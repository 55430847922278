import { Swiper } from "swiper" 
import { Navigation, Pagination, Autoplay } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { useEffect, useState } from "react"
import "./Banners.css"
import { Button, ButtonPrimary, ButtonInline } from "../../ui/Buttons"
import styled from "styled-components"

import banner1 from "./images/banner1.png"
import banner2 from "./images/banner2.png"

const BannerButton = styled(ButtonPrimary)`
    font-size: 14pt;
    margin-top: 20px;
    font-weight: 500;
`

export function Banners() {
    const [ currentSlide, setCurrentSlide ] = useState(0)
    useEffect(() => {
        const swiper = new Swiper(".banners-swiper", {
            slidesPerView: 1,
            modules: [Navigation, Pagination, Autoplay],
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            on: {
                slideChange: () => {
                    setCurrentSlide(swiper.activeIndex)
                }
            }
        })
    }, [])

    return (
        <div className="banners-body">
            <div className="banners-swiper">
                <div className="swiper-wrapper">
                    <BannerSlide text={"Apple iPhone 16 Pro и 16 Pro Max"} image={banner1} to={"/iphone/all"}/>
                    <BannerSlide text={"Apple iPhone 16 и 16 Plus"} image={banner2} to={"/iphone/all"}/>
                </div>
            </div>
            <div className="banner-buttons">
                <BannerSlideButton text={"iPhone 16 Pro и Pro Max"} slide={0} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide}/>
                <BannerSlideButton text={"iPhone 16 и 16 Plus"} slide={1} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide}/>
            </div>
        </div>
    )
}

function BannerSlide({text, image, to}) {
    return (
        <div className="swiper-slide">
            <div className="banner">
                <div className="banner-text">
                    {text}
                    <BannerButton to={to}>Перейти</BannerButton>
                </div>
                <img src={image}/>
            </div>
        </div>
    )
}

function BannerSlideButton({text, slide, currentSlide, setCurrentSlide}) { 
    const handleClick = () => {
        const swiper = document.querySelector(".banners-swiper").swiper
        if (swiper) {
            swiper.slideTo(slide)
        }
    }

    return (
        <div className={`banner-slide-button ${slide === currentSlide ? "banner-slide-button-active" : ""}`} onClick={handleClick}>
            {text}
        </div>
    )
}