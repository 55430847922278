import React, { useEffect, useState } from 'react';
import { fetchItems, updateItem, deleteItem } from './api';
import * as XLSX from 'xlsx';
import axios from 'axios';

function AdminPage() {
    const [items, setItems] = useState([]);
    const [editingItem, setEditingItem] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [uploadedItems, setUploadedItems] = useState([]);

    useEffect(() => {
        if (isAuthenticated) {
            loadItems();
        }
    }, [isAuthenticated]);

    const loadItems = async () => {
        const data = await fetchItems();
        setItems(data);
    };

    const handleEditChange = (id, field, value) => {
        setEditingItem((prev) => ({
            ...prev,
            [id]: { ...prev[id], [field]: value },
        }));
    };

    const handleSave = async (id) => {
        const originalItem = items.find((item) => item.id === id);
        const updatedItem = { ...originalItem, ...editingItem[id] };

        const success = await updateItem(updatedItem);
        if (success) {
            alert('Обновлено');
            loadItems();
            setEditingItem((prev) => ({ ...prev, [id]: {} }));
        } else {
            alert('Ошибка');
        }
    };

    const handleDelete = async (id) => {
        const success = await deleteItem(id);
        if (success) {
            alert('Удалено');
            loadItems();
        } else {
            alert('Ошибка');
        }
    };

    const handleExportToXLS = () => {
        const data = items.map((item) => ({
            'Категория': item.model || '',
            'Название': item.title || '',
            'Идентификатор': item.id,
            'Описание': '',
            'Короткое описание': '',
            'Цена': item.price || '',
            'Фото': item.photos?.[0]
                ? `https://shopmobilaserver.ru/items/getImage?image=${item.photos[0]}`
                : '',
            'Популярный товар': 'Нет',
            'В наличии': 'Да',
            'Количество': '10',
            'Единицы измерения': 'Штука',
            'Ссылка': `https://shopmobila.ru/item/${item.id}`,
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Items');

        XLSX.writeFile(workbook, 'items_export.xlsx');
    };

    const handleExportToSimpleXLS = () => {
        const data = items.map((item) => ({
            ID: item.id,
            'Item Name': item.title || '',
            Price: item.price || '',
            Model: item.model || '', // Added the Model column
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'SimpleExport');
    
        XLSX.writeFile(workbook, 'prices.xlsx');
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            alert('Выберите файл');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(
                'https://shopmobilaserver.ru/items/uploadedFile',
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            console.log('Uploaded and processed items:', response.data.items);
            setUploadedItems(response.data.items || []);
            alert('Цены обновлены');
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Ошибка');
        }
    };

    const handleLogin = () => {
        if (password === 'Mobila2222!') {
            setIsAuthenticated(true);
        } else {
            alert('Неправильный пароль');
            setPassword('');
        }
    };

    if (!isAuthenticated) {
        return (
            <div>
                <h1>Admin Login</h1>
                <input
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Login</button>
            </div>
        );
    }

    return (
        <div>
            <h1>Admin</h1>
            <button onClick={handleExportToXLS}>Экспорт в яндекс</button>
            <button onClick={handleExportToSimpleXLS}>Экспорт для цен</button>
            <input type="file" onChange={handleFileUpload} />
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Название</th>
                        <th>Модель</th>
                        <th>Наличие</th>
                        <th>Цена</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>
                                <input
                                    type="text"
                                    value={editingItem[item.id]?.title || item.title}
                                    style={{ width: '650px' }}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'title', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={editingItem[item.id]?.model || item.model}
                                    style={{ width: '180px' }}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'model', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <select
                                    value={editingItem[item.id]?.availability || item.availability}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'availability', e.target.value)
                                    }
                                >
                                    <option value="available">Available</option>
                                    <option value="unavailable">Unavailable</option>
                                    <option value="delivery">Delivery</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={editingItem[item.id]?.price || item.price}
                                    style={{ width: '100px' }}
                                    onChange={(e) =>
                                        handleEditChange(item.id, 'price', e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <button onClick={() => handleSave(item.id)}>Save</button>
                                <button onClick={() => handleDelete(item.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {uploadedItems.length > 0 && (
                <div>
                    <h2>Uploaded Items</h2>
                    <ul>
                        {uploadedItems.map((item, index) => (
                            <li key={index}>
                                {item.id} - {item.itemName} - {item.price}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default AdminPage;
